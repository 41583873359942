<template>
  <div
    class="d-flex"
    :style="
      getMarginCss(pro.style, isDesktopView) +
      'justify-content:' +
      pro.style.position +
      ';'
    "
  >
    <LazyImage
      :src="getImage"
      :key="getImage"
      :style="getImageStyles"
      :title="`Custom Inline Image`"
      :alt="`Custom Inline Image`"
      class="Img-Fluid"
    />
  </div>
</template>

<script>
import cssFunctionMixin from "../../customize/mixin/cssFunction";
export default {
  mixins: [cssFunctionMixin],
  props: ["pro", "isDesktopView"],
  computed: {
    getImageStyles() {
      const {
        backgroundColor,
        backgroundSize,
        borderColor,
        position,
        borderRadius,
        borderSize,
        desktopHeight,
        desktopWidth,
        mobileWidth,
        mobileHeight,
      } = this.pro.style;

      const width =
        position == "fullWidth"
          ? `width:100%;`
          : this.isDesktopView
          ? `width:${desktopWidth}px;`
          : ` width:${mobileWidth}px;`;
      const height = this.isDesktopView
        ? `height:${desktopHeight}px;`
        : `height:${mobileHeight}px;`;
      return (
        `border-radius:${borderRadius}px;border:${borderSize}px solid ${borderColor};background-color:${backgroundColor};object-fit:${backgroundSize};` +
        width +
        height
      );
    },

    getImage() {
      if (this.isDesktopView) {
        return this.pro.imageSrc;
      }

      const useMobileImage = !!this.pro.useMobileImage;
      const mobileSrc = this.pro.mobileSrc || "";

      return useMobileImage && mobileSrc !== "" ? mobileSrc : this.pro.imageSrc;
    },
  },
};
</script>
